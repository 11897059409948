<template>
    <Page
        :title="title"
        icon="mdi-file-excel"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        <file-upload
            label="Excel Data File"
            file-type="excel import"
            @set-files="event => setFiles(event)"
        />

        <v-container v-if="fileId">
            <btn @click="extract">Extract</btn>
        </v-container>
    </Page>
</template>
<script>
import FileUpload from '@/components/form/fields/fileUpload/FileUploadField';
import api from "@/api";

export default {
    components: { FileUpload },
    data() {
        return {
            title: 'Extract Excel Data',
            loadingMessage: 'Retrieving results.',
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
            fileId: '5f3b1e0935548937e3a28836',
        };
    },
    methods: {
        setFiles(event) {
            try {
                this.fileId = event[0].id;
                console.log('fileId - ', this.fileId);
            } catch (e) {}
        },
        async extract() {
            console.log('extracting');
            const result = await api.post(this.$store, 'file/exportexcel', { id: this.fileId });
            console.log('result', JSON.stringify(result.data, null, 2));
        }
    },
};
</script>
